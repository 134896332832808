import { httpsCallable } from 'firebase/functions';
import db, { functions } from '../config/firebaseDb';
import { doc, serverTimestamp, setDoc } from 'firebase/firestore';

interface CreateGroup {
	tokens: string[];
	groupName: string;
}

async function createGroup(body: CreateGroup) {
	const request = httpsCallable<CreateGroup, { notification_key: string }>(functions, 'notificacao-CriarGrupo');

	const { data } = await request(body);

	return data;
}

async function getGroup(groupName: string) {
	const request = httpsCallable<{ groupName: string }, { notification_key: string }>(functions, 'notificacao-GetGrupo');
	try {
		const { data } = await request({ groupName });
		return data;
	} catch (error) {
		console.error(error);
		return;
	}
}

interface UpdateGroup {
	groupName: string;
	groupToken: string;
	tokens: string[];
}

async function addGroup(body: UpdateGroup) {
	const request = httpsCallable<UpdateGroup, { notification_key: string }>(functions, 'notificacao-AdicionarGrupo');

	const { data } = await request(body);

	return data;
}

async function removeGroup(body: UpdateGroup) {
	const request = httpsCallable<UpdateGroup, { notification_key: string }>(functions, 'notificacao-RemoverGrupo');

	const { data } = await request(body);

	return data;
}

interface SendGroup {
	groupToken: string;
	data: object;
}

async function sendGroup(body: SendGroup) {
	const request = httpsCallable<SendGroup, boolean>(functions, 'notificacao-NotificarGrupo');

	await request(body);
}

interface UserDevice {
	token: string;
	userId: string;
}

async function setUserDevice({ userId, token }: UserDevice) {
	const docRef = doc(db, `UsuarioToken/${token}`);

	await setDoc(docRef, { DthRegistro: serverTimestamp(), UidRegistro: userId });
}

export const notification = {
	createGroup,
	getGroup,
	addGroup,
	removeGroup,
	sendGroup,
	setUserDevice
};
