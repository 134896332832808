import { getAuth, onAuthStateChanged, signOut as firebaseSignOut, User } from 'firebase/auth';
import { useEffect, useState } from 'react';
import { clearFCM } from '../utils/clearFCM';

const auth = getAuth();

export function useUserAuth(): User | undefined {
	const [user, setUser] = useState<User | undefined>(undefined);

	useEffect(() => {
		const fetchData = async () => {
			const unsubscribeFromAuthStateChanged = onAuthStateChanged(auth, firebaseUser => {
				console.log('>>> onAuthStateChanged >>> ');
				if (firebaseUser) {
					setUser(firebaseUser);
					localStorage.setItem('userEmail', firebaseUser.email ?? '-');
					localStorage.setItem('userName', firebaseUser.displayName ?? '-');
				} else {
					setUser(undefined);
					localStorage.removeItem('userEmail');
					localStorage.removeItem('userName');
				}
			});
			return () => unsubscribeFromAuthStateChanged();
		};

		fetchData();
	}, []);

	return user;
}

export async function signOut(): Promise<void> {
	try {
		if (auth.currentUser?.uid) clearFCM(auth.currentUser.uid);
		await firebaseSignOut(auth);
		removeServiceWorker();
		console.log('Usuário desconectado com sucesso.');
	} catch (error) {
		console.error('Erro ao fazer logout:', error);
	}
}

function removeServiceWorker() {
	if ('serviceWorker' in navigator) {
		navigator.serviceWorker
			.getRegistrations()
			.then(registrations => {
				for (const registration of registrations) {
					registration.unregister().then(unregistered => {
						if (unregistered) {
							console.log('Service Worker unregistered successfully');
						}
					});
				}
			})
			.catch(error => {
				console.error('Error unregistering Service Worker:', error);
			});
	}
}
