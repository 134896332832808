import { doc, getDoc } from 'firebase/firestore';
import db from '../config/firebaseDb';
import type { GlobalConfig } from '../assets/@types/config';

export async function getConfig() {
	try {
		await setGlobalConfig();
	} catch (error) {
		console.log(error);
	}
}

async function setGlobalConfig() {
	const docRef = doc(db, 'SysParam', 'Global');

	const snap = await getDoc(docRef);

	localStorage.setItem(snap.id, JSON.stringify(snap.data()));
}

export function getLocalGlobalConfig(): GlobalConfig | null {
	const json = localStorage.getItem('Global');
	if (!json) return null;
	return JSON.parse(json);
}
