import { User } from 'firebase/auth';
import { addDoc, collection, doc, runTransaction } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable, deleteObject } from 'firebase/storage';
import { v4 as uuidv4 } from 'uuid';

import { UploadFile } from '../components/Globals/UploadForm/UploadForm';
import db, { storage } from '../config/firebaseDb';
import { ChatPropsFB, type ChatProps } from '../utils/typesData';

export async function criarMensagemAnexo(anexos: UploadFile[], docId: string, user: User) {
	const collectionRef = collection(db, 'AppContato', docId, 'Mensagem');
	for await (const anexo of anexos) {
		const { downloadUrl, fileRef } = await armazenarArquivo(anexo, docId);
		downloadUrl;
		const message = criarMensagemFB(user, downloadUrl, fileRef, anexo.descricao);
		addDoc(collectionRef, message);
	}
}

function criarMensagemFB(user: User, FileUrl: string, fileRef: string, descricao: string) {
	const msg: ChatPropsFB = {
		_id: uuidv4(),
		text: descricao ?? '',
		Texto: descricao ?? '',

		DthRegistro: new Date(),
		createdAt: new Date(),

		UidRegistro: user.uid ?? '',
		NomeRegistro: user.displayName ?? user.email ?? '',

		file: FileUrl,
		FileUrl,
		fileRef,

		Plataforma: 'web-cliente',
		user: {
			_id: user.uid,
			uid: user.uid,
			name: user.displayName ?? user.email ?? ''
		}
	};
	return msg;
}

async function armazenarArquivo({ arquivo }: UploadFile, docId: string) {
	const storageRef = ref(storage, `/AppContato/${docId}/`);

	const fileRef = ref(storageRef, arquivo.name);
	const { ref: uploadRef } = await uploadBytesResumable(fileRef, arquivo);
	const downloadUrl = await getDownloadURL(uploadRef);

	return { downloadUrl, fileRef: uploadRef.fullPath };
}

export async function removerArquivo(message: ChatProps, chatId: string) {
	const docRef = doc(db, 'AppContato', chatId, 'Mensagem', message.id);
	const storageRef = ref(storage, message.fileUrl);

	try {
		await runTransaction(db, async transaction => {
			await deleteObject(storageRef);
			transaction.delete(docRef);
		});
	} catch (error) {
		console.log(error);
	}
}
