import Typography from '@mui/material/Typography';
import React, { CSSProperties, Dispatch, SetStateAction, useState } from 'react';
import UploadList from './UploadList';
import InputCustom from '../InputCustom';
import { limiteSizeBytes, limiteSizeMegabytes } from '../../../constants/upload.constants';
import styled from 'styled-components';
import { AttachFile } from '@mui/icons-material';

export interface UploadFile {
	descricao: string;
	arquivo: File;
}

export interface UploadFormProps {
	arquivos: UploadFile[];
	setFile: Dispatch<SetStateAction<UploadFile[]>>;
}

export function UploadForm({ setFile, arquivos }: UploadFormProps) {
	const [descricao, setDescricao] = React.useState('');
	const [error, setError] = useState<Error>();
	function handleFiles(target: EventTarget & HTMLInputElement) {
		setError(undefined);
		const files = target.files;
		if (!files) return;

		const arquivo = files.item(0);

		if (!arquivo) return;

		if (!arquivo?.size) return;

		if (arquivo?.size > limiteSizeBytes) {
			setError({
				message: `O arquivo excede o tamanho máximo permitido de ${limiteSizeMegabytes}MB`,
				name: 'Anexo excede o tamanho'
			});
			target.value = '';
			return;
		}

		setFile(prev => {
			return [...prev.filter(upload => upload.arquivo.name !== arquivo.name), { descricao, arquivo }];
		});
		setDescricao('');
	}
	return (
		<div style={ContainerColumnStyle}>
			<Typography
				variant="h2"
				sx={{
					fontSize: '18px',
					fontWeight: '500',
					marginBottom: '5px',
					textTransform: 'uppercase'
				}}
			>
				Anexar arquivos
			</Typography>
			<div style={ContainerRowStyle}>
				<InputCustom
					placeholder="Informe a descrição do anexo"
					value={descricao}
					error={!!error}
					helperText={error?.message}
					required
					onChange={({ currentTarget: { value } }) => setDescricao(value)}
				/>
				<ButtonAttach disable={!descricao}>
					<AttachFile />
					<InputFile multiple type="file" onChange={e => handleFiles(e.currentTarget)} />
				</ButtonAttach>
			</div>
			{arquivos.length > 0 && <UploadList arquivos={arquivos} setFile={setFile} />}
		</div>
	);
}

const ContainerRowStyle: CSSProperties = {
	display: 'flex',
	gap: '5px',
	alignItems: 'start',
	height: '100%',
	width: '100%'
};

const ContainerColumnStyle: CSSProperties = {
	display: 'flex',
	flexDirection: 'column',
	height: '100%',
	width: '100%',
	marginBottom: '30px'
};

export const ButtonAttach = styled.div<{ disable: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: white;
	background-color: ${({ disable }) => (disable ? '#b5b5b5' : '#d32f2f')};
	height: 55.96px;
	pointer-events: ${({ disable }) => disable && 'none'};
	aspect-ratio: 1;
	border-radius: 4px;
	position: relative;
	cursor: pointer;
`;

export const InputFile = styled.input`
	position: absolute;
	opacity: 0;
	cursor: pointer;
	width: 40px;
	height: 40px;
`;
