/* eslint-disable */
import { Box, Rating } from '@mui/material';
import { styled } from '@mui/material/styles';
import { collection, doc, onSnapshot, orderBy, query, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import * as S from './styles';
import Chat from '../Chat';
import ButtonCustom from '../../../../components/Globals/ButtonCustom';
import InputCustom from '../../../../components/Globals/InputCustom';
import Modal from '../../../../components/Globals/Modal';
import RadioGroupCustom from '../../../../components/Globals/RadioGroupCustom';
import TextAreaCustom from '../../../../components/Globals/TextAreaCustom';
import TextCustom from '../../../../components/Globals/TextCustom';
import db, { auth } from '../../../../config/firebaseDb';
import { optionsPriority } from '../../../../utils/mockup';
import { ChatProps, RequestData } from '../../../../utils/typesData';

const StyledRating = styled(Rating)({
	fontSize: '40px',
	'& .MuiRating-iconFilled': {
		color: '#cc2c2d'
	}
});

const RequestItemForm = (props: RequestData) => {
	const {
		id,
		Assunto,
		PrioridadeId,
		Responsavel,
		ServicoDes,
		StatusDes,
		Protocolo,
		TipoServico,
		Cliente,
		StatusId,
		Avaliacao
	} = props;

	const ChatId = id; //Id de AppContato
	const [showModalChat, setShowModalChat] = useState(false);
	const [readOnly, setReadOnly] = useState(true);
	const [rating, setRating] = useState(Avaliacao ?? 0);
	if (!id) setReadOnly(false); // Modo Inserção
	const user = auth.currentUser;
	const [messages, setMessages] = useState<ChatProps[]>([]);

	//--------------------------------------------------------
	const saveRating = async () => {
		if (rating < 1) {
			alert('Sua nota de avaliação deve ser entre 1 e 5 estrelas.');
			return;
		}
		try {
			await updateDoc(doc(db, 'AppContato', id), { Avaliacao: rating });
			alert('Sua avaliação foi registrada com sucesso.');
		} catch (error: any) {
			console.warn('ERRO >>> ', error);
			alert('Um erro ocorreu: ' + error?.message);
		}
	};

	const renderRating = () => {
		if (StatusId != '99') return null;

		return (
			<>
				<StyledRating
					name="customized-color"
					precision={1}
					defaultValue={0}
					value={rating}
					onChange={(ev, newValue) => {
						setRating(newValue ?? 0);
					}}
				/>
				<ButtonCustom
					variant="outlined"
					sx={{ width: '350px', color: '#000', border: '3px solid #000' }}
					onClick={saveRating}
				>
					AVALIAR SERVIÇO
				</ButtonCustom>
			</>
		);
	};

	const handleCloseModalChat = () => {
		setShowModalChat(false);
	};

	const handleOpenModalChat = () => {
		setShowModalChat(true);
	};

	const Row = Box;

	const openFile = (url: string) => {
		window.open(url, '_blank');
	};

	useEffect(() => {
		if (user == undefined || user == null) return;

		const collectionRef = collection(db, `AppContato/${id}/Mensagem`);
		const q = query(collectionRef, orderBy('createdAt', 'asc'));

		const unsub = onSnapshot(q, querySnapshot => {
			// console.log('onSnapshot acionado.... ', user?.uid);
			const newMessages: ChatProps[] = querySnapshot.docs.map(doc => {
				const dados = doc.data();
				console.log(doc.data());
				const _message = dados.Texto ?? '';
				return {
					id: doc.id,
					source: dados.UidRegistro === user?.uid ? 'sent' : 'recived',

					type: dados.fileRef ? 'file' : 'message',
					url: dados.file ?? dados.image ?? '',
					fileUrl: dados.FileUrl ?? dados.ImagemUrl ?? '',

					message: dados.ImagemUrl ? 'Anexo' : _message,
					text: dados.ImagemUrl ? 'Anexo' : _message,

					time: dados.createdAt.toDate().toString(), // Certifique-se de ajustar conforme necessário
					user: {
						uid: dados.UidRegistro,
						name: dados.NomeRegistro,
						avatar: '' // Adicione a URL do avatar se disponível
					}
				};
			});
			setMessages(newMessages);
		});

		// Retorna uma função para cancelar a inscrição do listener quando o componente for desmontado
		return () => {
			unsub();
			console.log('<<< [Chat] Cancel Listner >>>');
		};
	}, []);

	return (
		<S.Container>
			{showModalChat && (
				<Modal onClose={handleCloseModalChat} isChat>
					<Chat
						openFile={openFile}
						chat={messages}
						assunto={Assunto}
						protocolo={Protocolo}
						chatId={ChatId}
						finished={StatusId == '99'}
					/>
				</Modal>
			)}
			<InputCustom externallabel="EMPRESA" value={Cliente} disabled />
			<InputCustom externallabel="TIPO DE SERVIÇO" value={TipoServico} disabled />
			<InputCustom externallabel="ASSUNTO" value={Assunto} disabled />
			<Row
				sx={{
					display: 'flex',
					gap: '40px',
					justifyContent: 'space-between',
					maxWidth: '80%',
					flexWrap: 'wrap'
				}}
			>
				<RadioGroupCustom
					externallabel="PRIORIDADE"
					disabled
					defaultValue={PrioridadeId.toString()}
					options={optionsPriority}
					onChangeValue={function (e): void {
						console.log(e);
					}}
				/>
				{/* <TextCustom externallabel="STATUS" text={StatusDes} startIcon="/images/icons/IconTime.svg" /> */}
				<TextCustom externallabel="STATUS" text={StatusDes} disabled={readOnly} />
				<TextCustom externallabel="RESPONSÁVEL" text={Responsavel || ''} disabled={readOnly} />
			</Row>
			<TextAreaCustom
				customlabel="DESCRIÇÃO  DA SOLICITAÇÃO"
				placeholder="Ex: Solicitação descrita aqui!"
				value={ServicoDes}
				disabled={readOnly}
			/>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'column',
					flex: 1,
					gap: '20px',
					alignItems: 'center'
				}}
			>
				<ButtonCustom
					variant="contained"
					color="error"
					sx={{ width: '350px' }}
					onClick={function () {
						handleOpenModalChat();
					}}
				>
					MENSAGENS
				</ButtonCustom>

				{renderRating()}
			</Box>
		</S.Container>
	);
};

export default RequestItemForm;
